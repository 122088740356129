
import { defineComponent, ref } from "vue";
import {
  ReturnForm,
  ReturnType,
  SaleChannel,
} from "@/types/return/Return.interface";
import InputTextCustom from "@/components/inputs/InputText.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: {
    InputTextCustom,
    InputSelect,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const returnType = ref<ReturnType[]>([
      { id: 1, name: "ตืนสินค้า/คืนเงิน" },
      { name: "แลกเปลี่ยนสินค้า", id: 2 },
    ]);

    const saleChannel = ref<SaleChannel[]>([
      { id: 1, name: "Facebook" },
      { id: 2, name: "Line" },
    ]);

    const form = ref<ReturnForm>({
      returnType: 1,
      saleChannel: 0,
      invoiceNo: "",
      name: "",
    });

    const onReturnTypeChange = (event: any) => {
      form.value.returnType = event.value;
    };

    const onSaleChanelChange = (event: any) => {
      form.value.saleChannel = event.value;
    };

    const onSubmitReturnForm = () => {
      router.push(
        `/${route.params.initialId}/return/request/0?sessionId=${route.query.sessionId}`
      );
    };

    return {
      returnType,
      form,
      onReturnTypeChange,
      onSaleChanelChange,
      saleChannel,
      onSubmitReturnForm,
    };
  },
});
